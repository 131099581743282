.loginContainer {
    display: flex;
    height: 100vh;

    .leftImg {
        width: 50%;
        height: 100%;
        background-image: url('../../images/pexels-amine-m\'siouri-2108845.jpg');
        background-size: cover;
        background-position: center;
    }

    .rightSide {
        width: 50%;
        height: 100%;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;

        .form {
            color: white;

            h1 {
                font-weight: 100;
                font-size: 40px;
                margin-bottom: 70px;
            }

            .row {
                margin-bottom: 20px;
            }

            input {
                display: block;
                width: 120%;
                margin-left: -10%;
                border: none;
                background-color: black;
                color: white;
                font-size: 15px;
                border-bottom: 1px solid gray;
                padding: 7px 5px;
                box-sizing: border-box;
            }

            input[type="submit"] {
                border: 1px solid gray;
                // padding: 25px 0 10px 0;
                margin-top: 35px;
                color: gray;
                transition: all 0.3s;

                &:hover {
                    color: white;
                    border-color: white;
                    // background-color: gray;
                    cursor: pointer;
                }
            }


                

            .inputBorder {
                height: 1px;
                width: 0px;
                margin-left: -10%;
                background-color: white;
                transition: all 0.3s;
            }
            input:focus ~ .inputBorder {
                width: 120%;
              }

            
        }
    }
}



input:focus,
textarea:focus {
  outline: none;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-internal-autofill-selected,
input:-internal-autofill-previewed,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #181818 !important;
  color: white !important;
  -webkit-background-clip: text;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}