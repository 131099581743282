#productsContainer {
    // position: relative;
    h1 {
        font-weight: 200;
        border-bottom: 1px solid rgba(255,255,255,0.8);
        display: inline-block;
        padding: 5px 50px;
        font-size: 50px;
    }

    #products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
        padding: 20px;

        a {
            color: rgba(255,255,255,0.8);
            text-decoration: none;
        }
    }

    .product {
        border: 1px solid rgba(255,255,255,0.8);
        margin: 10px;
        width: 200px;
        border-radius: 4px;
        transition: all 0.3s;

        &:hover {
            cursor: pointer;
            color: white;
            border-color: white;
        }

       

        .productImage {
            width: 100%;
            height: 150px;
            background-position: center;
            background-size: cover;
        }

        .productName {
            padding: 20px 10px;
        }
    }

    #plusButton {
        position: fixed;
        bottom: 20px;
        right: 20px;
        border: 1px solid gray;
        color: gray;
        align-items: center;
        justify-content: center;
        font-weight: 100;
        transition: all 0.3s;
        overflow: hidden;
        text-align: right;

        input {
            display: block;
            border: none;
            background-color: black;
            color: white;
            font-size: 15px;
            border-bottom: 1px solid gray;
            padding: 7px 5px;
            box-sizing: border-box;
            margin: 10px;
            box-sizing: border-box;
            width: 90%;
        }
        
        .inputBorder {
            height: 1px;
            width: 0px;
            background-color: white;
            transition: all 0.3s;
            margin: 10px;
            margin-top: -10px;
        }
        
        input:focus ~ .inputBorder {
            width: 90%;
        }

        h3 {
            font-size: 20px;
            text-align: center;
        }

        &:hover {
            color: white;
            border-color: white;
            cursor: pointer;
        }

        .plus {
            margin-top: -5px;
        }

        #createBtn {
            // color: white;
            font-weight: 300;
            border: 1px solid gray;
            display: inline-block;
            padding: 5px 15px;
        }
    }
}

