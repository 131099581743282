#productDetailContainer {
    h1 {
        font-weight: 200;
        border-bottom: 1px solid rgba(255,255,255,0.8);
        display: inline-block;
        padding: 5px 50px;
        font-size: 50px;
    }

    #topRow {
        display: flex;
        justify-content: center;
        padding: 50px;
    }


    #imagesContainer {
        width: 500px;
        // padding: 50px 20px;

        #mainImage {
            border: 1px solid rgba(255,255,255,0.8);
            height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            margin-bottom: 20px;
            background-position: center;
            background-size: cover;
            color: black;

            &:hover {
                cursor: pointer;
                color: white;
                border-color: white;
            }
        }

        #detailImagesContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .detailImage {
                width: 100px;
                height: 150px;
                border: 1px solid rgba(255,255,255,0.8);
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-position: center;
                background-size: cover;
                transition: all 0.1s;

                &:hover {
                    cursor: pointer;
                    border-color: white;
                }

                div {
                    font-size: 70px;
                    font-weight: 100;
                }

                .addSubImage {
                    margin-top: -10px;
                }
            }

            .addSubImageContainer {
                color: gray;
                border: 1px solid gray;
                transition: all 0.3s;

                &:hover {
                    cursor: pointer;
                    color: white;
                    border-color: white;
                }
            }
        }
    }

    #rightSide {
        width: 60%;

        #smallInputs {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;


            .inputContainer {
                display: flex;
                align-items: center;
                height: 30px;
                margin-left: 60px;
                margin-bottom: 60px;
    
                
            }
        }

        

        #amazonLinkContainer {
            .inputContainer {
                width: 90%;
                display: flex;
                align-items: center;
                margin: 0 60px;

                #amazonLinkRow {
                    width: 100%;
                }

                input {
                    width: 100%;
                }
            }
        }

        #descriptionsContainer {
            // width: 60%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 40px;
            margin: 60px 60px 0 60px;

            .descriptionContainer {
                // width: 300px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 30px;

                h2 {
                    margin-bottom: 10px;
                    font-weight: 200;
                }

                p {
                    text-align: left;
                }

                .editDescriptionButton {
                    border: 1px solid gray;
                    color: gray;
                    margin-top: 10px;
                    padding: 5px;
                    transition: all 0.3s;

                    &:hover {
                        color: white;
                        border-color: white;
                        cursor: pointer;
                    }

                }
            }

            .addDescription {
                border: 1px solid gray;
                display: flex;
                justify-content: center;
                align-items: center;
                color: gray;

                font-size: 70px;
                font-weight: 100;
                transition: all 0.3s;

                div {
                    margin-top: -10px;
                }

                &:hover {
                    color: white;
                    border-color: rgba(255,255,255,0.8);
                    cursor: pointer;
                }
            }

        }
       
    }

    #categories {
        h2 {
            font-weight: 400;
        }

        #categorySlug {
            color: gray;
            font-weight: 300;
        }

        #subCategoryHeader {
            margin-top: 0px;
        }

        #options {
            display: flex;
            justify-content: center;
        }

        .optionContainer {
            display: flex;
            align-items: center;
            margin: 20px;

            .optionCircle {
                width: 25px;
                height: 25px;
                border-radius: 100%;
                border: 1px solid gray;
                transition: all 0.3s;
                margin-right: 10px;

                &:hover {
                    cursor: pointer;
                    border-color: white;
                }
            }
        }
    }

    input {
        display: block;
        border: none;
        background-color: black;
        color: white;
        font-size: 15px;
        border-bottom: 1px solid gray;
        padding: 7px 5px;
        box-sizing: border-box;
        margin-left: 10px;
        width: 100%;
    }

    .inputBorder {
        height: 1px;
        width: 0px;
        background-color: white;
        transition: all 0.3s;
        margin-left: 10px;
    }

    input:focus ~ .inputBorder {
        width: 100%;
    }

    input[type="submit"] {
        width: 90%;
        border: 1px solid gray;
        // padding: 25px 0 10px 0;
        margin: 0 60px;
        color: gray;
        transition: all 0.3s;
    
        &:hover {
            color: white;
            border-color: white;
            // background-color: gray;
            cursor: pointer;
        }
    }

    #importantButtons {
        display: flex;
        padding: 25px 100px 50px 100px;

        #deleteButton {
            display: block;
        border: none;
        background-color: black;
        color: white;
        font-size: 15px;
        border-bottom: 1px solid gray;
        padding: 7px 5px;
        box-sizing: border-box;
        margin-left: 10px;
        width: 100%;
        width: 90%;
        border: 1px solid rgba(255, 0, 0, 0.6);
        // padding: 25px 0 10px 0;
        margin: 0 60px;
        color: rgba(255, 0, 0, 0.6);
        transition: all 0.3s;
    
        &:hover {
            color: red;
            border-color: red;
            background-color: rgba(255, 0, 0, 0.4);
            // background-color: gray;
            cursor: pointer;
        }
        }
    }
}
