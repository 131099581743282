input[type="file"] {
    display: none;
  }

  #fileUploadButton {
    padding: 10px 20px;
    border: 1px solid black;
    color: black;
    font-weight: bold;
    border-radius: 5px;
  }
  
  #fileUploadButton:hover {
    cursor: pointer;
  }

  #mainImageModal {
    h2 {
        margin-bottom: 30px;
    }

    #modalButtons {
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: right;
        width: 90%;
        box-sizing: border-box;
        #cancelButton, #updateButton, #deleteButton {
            border: 1px solid black;
            padding: 5px 15px;
            width: 100px;
            text-align: center;
            border-radius: 5px;
            margin-right: 5px;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                background-color: rgba(255,255,255,0.3);
            }
        }

        #deleteButton {
          color: red;
          border-color: red;
        }
    }
  }

  #deleteImage {
    text-align: center;

    #modalButtons {
      position: absolute;
      bottom: 20px;
      display: flex;
      justify-content: center;
      width: 90%;
      box-sizing: border-box;
      #cancelButton, #updateButton, #deleteButton {
          border: 1px solid black;
          padding: 5px 15px;
          width: 100px;
          text-align: center;
          border-radius: 5px;
          margin-right: 5px;
          transition: all 0.3s;

          &:hover {
              cursor: pointer;
              background-color: rgba(255,255,255,0.3);
          }
      }

      #updateButton {
        border-color: red;
        color: red;
      }
  }
  }

  input {
    // display: block;
    width: 80%;
    // margin-left: -10%;
    border: none;
    // background-color: black;
    color: black;
    font-size: 15px;
    border-bottom: 1px solid gray;
    padding: 7px 5px;
    box-sizing: border-box;
}

  .ReactModal__Content--after-open {
    width: 500px;
    height: 200px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    // margin-right: -50%;
    transform: translate(30vw, 30vh);
    // inset: !important;
  }