#dashboardContainer {
    background-color: black;
    min-height: 100vh;
    color: rgba(255,255,255,0.8);

    #nav {
        display: flex;
        justify-content: space-between;
        padding: 15px 25px;
        margin-bottom: 50px;
        align-items: center;

        h1 {
            font-weight: 200;
            a {
                text-decoration: none;
                color: rgba(255,255,255,0.8);
            }
        }

        #signOutBtn {
            border: 1px solid rgba(255,255,255,0.8);
            padding: 5px 15px;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                color: white;
                border-color: white;
            }
        }
    }

}