#categories {

    a {
        color: rgba(255,255,255,0.8);
        text-decoration: none;
        height: 100%;

        div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    h1 {
        font-weight: 200;
        border-bottom: 1px solid rgba(255,255,255,0.8);
        display: inline-block;
        padding: 5px 50px;
        font-size: 50px;
    }

    #categoriesContainer {
        display: flex;
        padding: 0 20px;
        // flex-wrap: wrap;
        .category {
            width: 400px;
            height: 500px;
            background-position: center;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            margin-top: 50px;
            border-radius: 5px;
            transition: all 0.3s;

            h2 {
                font-weight: 300;
                font-size: 30px;
            }

            &:hover {
                color: white;
                cursor: pointer;
            }
        }

        .home {
            background-image: url('../../images/pexels-eberhard-grossgasteiger-1612351.jpg');
        }

        .gun {
            background-image: url('../../images/pexels-specna-arms-886454.jpg');
        }

        .fish {
            background-image: url('../../images/pexels-tolga-aslantürk-10802145.jpg');
        }

        .thumb {
            background-image: url('../../images/pexels-ashford-marx-12562554.jpg');
        }

        .replace {
            background-image: url('../../images/pexels-dan-cristian-pădureț-9966689.jpg');
        }
    }
}
